/**
 * The overlay provider is used to manage screen overlays such as Flyouts or modals, which are use to display content in a dynamic fashion based on the directive from the amplience request
 *
 * @module views/components/OverlayProvider
 * @memberof -Common
 */
import './OverlayProvider.scss';

import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef } from 'react';

import deepmerge from 'deepmerge';

import DynamicOverlayComponent from '../../components/DynamicOverlayComponent/DynamicOverlayComponent';
import Overlay from '../../components/Overlay/Overlay';
import useLoader from '../../hooks/useLoader/useLoader';
import { handleDXLNavigationType, processCloseAction } from '../../utils/clientActionProcessor/clientActionProcessor';
import { DXL_NAVIGATION_TYPE } from '../../utils/constants/action';
import { isServer } from '../../utils/device_detection/device_detection';
import { devLogger } from '../../utils/devMode/devMode';
import { handleEmptyObjects } from '../../utils/handleEmptyObjects/handleEmptyObjects';
import { isFunction } from '../../utils/types/types';
import { useLayerHostContext } from '../LayerHostProvider/LayerHostProvider';
import { usePageDataContext } from '../PageDataProvider/PageDataProvider';
import { useUserContext } from '../UserContextProvider/UserContextProvider';
import { UTILITY_LINKS_CLASS } from './../../../ui/modules/UtilityLinks/UtilityLinks';
import { getDialogPolyfill } from './getDialogPolyfill';
import * as utils from './OverlayProvider';

/**
 * Represents a OverlayProvider component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns OverlayProvider
 */
export const OverlayProvider = function( props ){
  const overlayRef = useRef();
  const userCancelled = useRef( false );
  const isClosingRef = useRef( false );
  const overlayOptions = useRef( {} );

  const [state, dispatch] = useReducer( overlayProviderReducer, configInitialState );
  const { dxlRequestStack } = useLayerHostContext();

  const {
    ariaLabel,
    backAction,
    backActionCallback,
    crossButtonVisibility,
    customClassName,
    displayOverlay,
    isSwatchModal,
    isVideo,
    alignment,
    closeAccessibilityLabel,
    content,
    onClose,
    opener,
    offsetElement,
    type,
    showDynamicFlyoutHeader,
    videoMeta
  } = state;

  // Keep a stable rerence to overlay options we need in helpers
  useEffect( () => {
    overlayOptions.current = { ...state };
  }, [displayOverlay, type, alignment] );

  const closeActionModel = useRef( { ...CLOSE_ACTION_MODEL } );

  const [loader, showLoader, hideLoader] = useLoader();

  /*
   * Callbacks
   *
   * -----------
   * public API:
   * -----------
   *
   * openOverlay()
   *    - accepts arguments from compenents and dispatches them to the reducer
   *
   * closeOverlay():
   *    - will initatiate dialog.close()
   *    - this is the public API method used by components
   *
   * updateContent():
   *    - components can call this to update the contents of an overlay
   *
   * updateOverlay():
   *    - components can call this to update the props
   *
   * updateBackAction():
   *    - components can call this to update the back action in the header
   *
   * updatecloseAction():
   *    - components can call this to update the on close DXL actions that need to execute
   *
   * -------------
   * internal API:
   * -------------
   *
   * onDialogClose():
   *   - happens when dialog.close() is called,
   *   - manages transitions
   *   - waits for close actions to run
   *   - dispatches to the reducer
   *
   * handleOutSideClick():
   *    - listens for clicks outside the modal and calls closeOverlay()
   *
   * onCancelClose();
   *    - listens for click on the 'X' icon in overlay flyout header and calls closeOverlay()
   *
   * onDisplayStateChange():
   *    - listens to display state and manages DOM effects
   *    - TODO: We need to take a look at when close actions lag, the scroll block will
   *            not be removed until the action is done processing. Should ask UX what
   *            the expectation is when a close action request lags/timesout.
   */

  // Handles executing the closing of the overlay
  const closeOverlay = useCallback( utils.composeOnClose( { overlayRef, userCancelled } ), [overlayRef] );

  // Handles clicking outside the overlay and closing
  const handleOutsideClick = useCallback(
    utils.composeHandleOutsideClick( { overlayRef, dxlRequestStack }, { closeOverlay } ),
    [closeOverlay]
  );

  // Handles opening the overlay
  const openOverlay = useCallback( utils.composeOnOpenOverlay( { dispatch } ), [dispatch] );

  // Handles the native on close event
  const onDialogClose = useCallback(
    utils.composeOnDialogClose(
      { opener, overlayRef, type, closeActionModel, userCancelled },
      { dispatch, openOverlay, showLoader, hideLoader }
    ),
    [opener, overlayRef, type, dispatch]
  );

  // Handles updating the content inside the overlay dynamically
  const updateContent = useCallback( utils.composeUpdateContent( { dispatch } ), [dispatch] );

  // Handles updating the aria-label inside the overlay dynamically
  const updateOverlay = useCallback( utils.composeUpdateOverlay( { dispatch } ), [dispatch] );

  // Handles updating the back action dynamically
  const updateBackAction = useCallback( utils.composeUpdateBackAction( { dispatch } ), [dispatch] );

  const updateCloseAction = useCallback( utils.composeUpdateCloseAction( { closeActionModel } ), [closeActionModel] );

  // Handles open/closing state changes
  const onDisplayStateChange = useCallback(
    utils.composeOnDisplayStateChange(
      { displayOverlay, overlayRef, userCancelled, overlayOptions },
      { handleOutsideClick, closeOverlay, onClose, onDialogClose }
    ),
    [displayOverlay, overlayRef, userCancelled, handleOutsideClick, closeOverlay, onClose]
  );
  useEffect( onDisplayStateChange, [onDisplayStateChange] );

  // Handles auto closing when we lose a session
  utils.useCloseOnSessionExpiration( { updateContent, closeOverlay } );

  /*
   * Derived state
   */
  const displayModal = displayOverlay && type === OVERLAY_TYPE.Modal;
  const displayFlyout = displayOverlay && type === OVERLAY_TYPE.Flyout;

  return (
    <OverlayContext.Provider
      value={ {
        backAction,
        backActionCallback,
        closeOverlay,
        crossButtonVisibility,
        displayOverlay,
        hidePageLoader: hideLoader,
        isOverlay,
        isClosingRef,
        onDialogClose,
        openOverlay,
        closeAccessibilityLabel,
        type,
        showDynamicFlyoutHeader,
        showPageLoader: showLoader,
        updateBackAction,
        updateContent,
        updateCloseAction,
        updateOverlay,
        offsetElement
      } }
    >
      { props.children }

      { ( displayModal || displayFlyout ) && (
        <Overlay
          { ...( displayModal && {
            overlayType: OVERLAY_TYPE.Modal,
            isVideoModal: isVideo,
            videoMeta: videoMeta,
            isSwatchModal: isSwatchModal
          } ) }
          { ...( displayFlyout && {
            overlayType: OVERLAY_TYPE.Flyout,
            showDynamicFlyoutHeader: showDynamicFlyoutHeader,
            offsetElement: offsetElement,
            crossButtonVisibility: crossButtonVisibility
          } ) }
          ref={ overlayRef }
          alignment={ alignment }
          ariaLabel={ ariaLabel }
          closeAccessibilityLabel={ closeAccessibilityLabel }
          customClassName={ customClassName }
          onClose={ onDialogClose }
        >
          { content }
        </Overlay>
      ) }

      { !!loader && (
        <div className='OverlayLoader'>{ loader }</div>
      ) }
    </OverlayContext.Provider>
  );
};

/**
 * Closes or updates overlay when we lose a session
 * @param {object} methods - methods
 * @param {function} methods.updateContent - updates overlay content
 * @param {function} methods.closeOverlay - closes overlay
 */
export const useCloseOnSessionExpiration = ( methods ) => {
  const { updateContent, closeOverlay } = methods || {};
  const { user } = useUserContext();
  const prevUser = useRef( user );
  const { data } = usePageDataContext();

  useEffect( () => {
    if( prevUser.current.loginStatus === user.loginStatus ){
      return;
    }

    const lostSession = prevUser.current.loginStatus && !user.loginStatus;
    const { loginAction } = data?.Page?.meta || {};

    if( lostSession && !loginAction ){
      closeOverlay();
    }
    else if( lostSession ){
      updateContent( <DynamicOverlayComponent action={ loginAction } /> );
    }

    prevUser.current = user;
  }, [user.loginStatus] );
};

/**
 * Represents a composeOnDisplayStateChange method,which calls on change Display State
 * @param {object} data - Arguments
 * @param {boolean} data.displayOverlay - State of the overlay, open/close
 * @param {object} data.overlayRef - Ref to the overlay element
 * @param {object} methods - Methods
 * @param {function} methods.handleOutsideClick - Handles outside click methods
 * @param {function} methods.closeOverlay - Handles closing the overlay
 * @param {function} methods.onClose - Handles the onClose events
 */
export const composeOnDisplayStateChange = ( data, methods ) => ( ) => {
  const { displayOverlay, overlayRef = {}, userCancelled = {}, overlayOptions = {} } = handleEmptyObjects( data );
  const { handleOutsideClick, closeOverlay, onClose, onDialogClose } = methods || {};
  const { type } = handleEmptyObjects( overlayOptions.current );

  if( !isServer() && displayOverlay && typeof HTMLDialogElement !== 'function' && overlayRef.current ){
    getDialogPolyfill().then( ( registerDialog ) => {
      registerDialog( overlayRef.current );
      overlayRef.current.showModal();
      overlayRef.current.addEventListener( 'close', onDialogClose );
    } );
  }

  // Manages open side effects
  if( displayOverlay ){
    // Setting the current scroll posoiton to body
    global.document.body.style.top = `-${global.scrollY}px`;

    overlayRef.current.showModal?.();
    // if the overlay is open then block page scrolling
    const overlaySetup = () => {
      global.document.body.classList.add( OVERLAY_SCROLL_BLOCK_CLASS );
      overlayRef.current.removeEventListener( 'transitionend', overlaySetup );
    };
    if( type === OVERLAY_TYPE.Modal ){
      overlaySetup();
    }
    else {
      overlayRef.current.addEventListener( 'transitionend', overlaySetup );
    }

    // Setup event listeners
    document.addEventListener( 'click', handleOutsideClick );
    document.addEventListener( OVERLAY_CLOSE_EVENT, closeOverlay );
    return;
  }

  // Manages close side effects
  const scrollY = global.document.body.style.top;
  global.document.body.classList.remove( OVERLAY_SCROLL_BLOCK_CLASS );
  global.document.body.style.top = '';

  // Restoring the scroll positon which was set earlier
  global.scrollTo( 0, parseInt( scrollY || '0', 10 ) * -1 );

  // Remove event listeners
  document.removeEventListener( 'click', handleOutsideClick );
  document.removeEventListener( OVERLAY_CLOSE_EVENT, closeOverlay );

  // On close callback
  if( isFunction( onClose ) ){
    onClose( {
      // Lets the caller know if this was a user cancel or programmatic/success close
      userCancelled: !!userCancelled.current
    } );
  }

  // Reset userCancelled status
  userCancelled.current = false;
};

/**
 * Represents a composeOnOpenOverlay method, which calls from openOverlay
 * @param {object} methods - Methods
 * @param {function} methods.dispatch - Overlay reducer dispatch method
 */
export const composeOnOpenOverlay = ( methods ) => ( overlayParams ) => {
  const { dispatch } = methods || {};

  if( !isFunction( dispatch ) ){
    return;
  }
  dispatch( { type: OVERLAY_ACTIONS.OPEN_OVERLAY, payload: overlayParams } );
};

/**
 * Represents a composeOnClose method, which calls from onCloseOverlay
 * @param {object} data - Argument
 * @param {object} data.overlayRef - Ref to the overlay element
 */
export const composeOnClose = ( data ) => ( eventData ) => {
  const { overlayRef, userCancelled } = data || {};

  if( !isFunction( overlayRef?.current?.close ) ){
    return;
  }

  // Allows programmatic callers to determine if this is a user cancel or on success close
  if( typeof eventData?.userCancelled === 'boolean' ){
    userCancelled.current = eventData.userCancelled;
  }

  overlayRef.current.close();
};

/**
 * Represents a composeHandleOutsideClick method,which handle Outside click functionaility
 * @param {object} methods - Methods
 * @param {function} methods.composeOnCancelClose - Handles closing the overlay
 */
export const composeHandleOutsideClick = ( data, methods ) => ( e ) => {
  const { overlayRef = {}, dxlRequestStack = {} } = handleEmptyObjects( data );
  const { closeOverlay } = handleEmptyObjects( methods );

  if( !closeOverlay || !overlayRef?.current ){
    return;
  }

  e.stopPropagation();
  if( dxlRequestStack?.current?.length || document?.querySelector( `.${UTILITY_LINKS_CLASS}` )?.contains( e?.target ) ){
    return;
  }

  const width = overlayRef.current.offsetWidth;
  const height = overlayRef.current.offsetHeight;
  const top = overlayRef.current.offsetTop;
  const left = overlayRef.current.offsetLeft;

  const clickedInModal = left <= e.clientX && e.clientX <= left + width && top <= e.clientY && e.clientY <= top + height;

  if( e.pointerType === 'mouse' && clickedInModal === false ){
    closeOverlay( { userCancelled: true } );
  }
};

/**
 * @const {object} CLOSE_ACTION_MODEL - Base model for close action
 */
export const CLOSE_ACTION_MODEL = {
  data: {
    action: undefined,
    loading: undefined,
    reOpen: false
  },
  methods: {}
};

/**
 * Represents a composeOnDialogClose method
 * @param {object} data - Arguments
 * @param {object} data.opener - Ref to the element that opened the overlay
 * @param {object} data.overlayRef - Ref to the overlay element
 * @param {object} data.closeActionModel - Ref for the close action model
 * @param {object} data.userCancelled - Ref that maintains whether or not this was a user cancel (click outside/X click)
 * @param {string} data.type - Type of overlay
 * @param {object} methods - Methods
 * @param {function} methods.dispatch - Overlay reducer dispatch method
 * @param {function} methods.showLoader - Show page loader
 * @param {function} methods.hideLoader - Hide page loader
 */
export const composeOnDialogClose = ( data, methods ) => ( event, eventData ) => {
  const { opener = {}, isClosingRef = {}, overlayRef = {}, type, closeActionModel = {}, userCancelled = {} } = handleEmptyObjects( data );
  const { dispatch, openOverlay, showLoader, hideLoader } = methods || {};

  if( !overlayRef.current || !type ){
    return;
  }

  isClosingRef.current = true;
  event?.stopPropagation();

  // Allows programmatic callers to determine if this is a user cancel or on success close
  if( typeof eventData?.userCancelled === 'boolean' ){
    userCancelled.current = eventData.userCancelled;
  }

  const overlayTeardown = () => {
    overlayRef.current?.removeEventListener( 'transitionend', overlayTeardown );

    utils.onOverlayTeardown(
      { opener, isClosingRef, closeActionModel, userCancelled },
      { dispatch, openOverlay, showLoader, hideLoader }
    );
  };

  // Display refresh action loader as soon as the flyout/modal starts to close
  if( closeActionModel.current?.data?.action?.graphql ){
    showLoader();
  }

  if( type === OVERLAY_TYPE.Modal ){
    overlayTeardown();
  }
  else {
    overlayRef.current.addEventListener( 'transitionend', overlayTeardown );
  }
};

/**
 * Helper to cleanup/execute post close tasks
 *
 * @param {object} data - Arguments
 * @param {object} data.opener - Ref to the element that opened the overlay
 * @param {object} data.closeActionModel - Ref for the close action model
 * @param {object} data.userCancelled - Ref that maintains whether or not this was a user cancel (click outside/X click)
 * @param {object} methods - Methods
 * @param {function} methods.dispatch - Overlay reducer dispatch method
 * @param {function} methods.showLoader - Show page loader
 * @param {function} methods.hideLoader - Hide page loader
 */
// We use a ref here because processCloseAction is currently polling the loading
// status of a potential close action so teardown can await before removing the
// full page loader.
//
// The flow of a close action is:
// 1. user closes via ESC, X, or outside click
// 2. Because DXL sends close and back actions to child modules, we have workarounds
//    that are managed outside our normal architecture. This is a stop gap until Overlay Wizard
//    is complete and properly modeled.
// 3. From the child component, the update close action helper updates this ref
// 4. Inside processCloseAction we poll the ref's loading keys to return Promise.resolve or not
// 5. This way, the async tear down function can then manage animating the close, blocking the
//    page w/ a full screen loader, then removing the loader when the close action is done.
export const onOverlayTeardown = async( data, methods ) => {
  const { opener = {}, isClosingRef = {}, closeActionModel = {}, userCancelled = {} } = handleEmptyObjects( data );
  const { dispatch, openOverlay, showLoader, hideLoader } = methods || {};

  if( !dispatch || !showLoader || !hideLoader ){
    return;
  }

  showLoader();

  devLogger( {
    title: '[OverlayProvider] processing closeAction | START',
    value: { data: closeActionModel.current?.data, closeActionModel, userCancelled }
  } );

  await processCloseAction( { closeActionModel, userCancelled } );

  devLogger( {
    title: '[OverlayProvider] processing closeAction | END',
    value: { data: closeActionModel.current?.data, closeActionModel, userCancelled }
  } );

  hideLoader();
  isClosingRef.current = false;

  // If we've received another overlay as the response for a close action, we can open it here
  if( isOverlay( closeActionModel.current?.data?.reOpenAction?.navigationType ) ){
    devLogger( '[OverlayProvider] closeAction returned another overlay', 1 );

    // We need to reset before opening the new overlay
    dispatch( { type: OVERLAY_ACTIONS.CLOSE_OVERLAY } );

    const action = { ...closeActionModel.current.data.reOpenAction };

    handleDXLNavigationType( { action }, { openOverlay } );

    closeActionModel.current = { ...CLOSE_ACTION_MODEL };

    return;
  }

  // Reset close action model
  closeActionModel.current = { ...CLOSE_ACTION_MODEL };

  dispatch( { type: OVERLAY_ACTIONS.CLOSE_OVERLAY } );

  // Added for Safari - page was scrolling to bottom on overlay close
  setTimeout( () => {
    opener.current?.focus?.();
  }, 0 );
};

/**
 * Represents a composeUpdateOverlay method which trigger dispatch method
 * @param {object} methods - Methods
 * @param {function} methods.dispatch - Overlay reducer dispatch method
 * @returns {updateContent}
 */
export const composeUpdateContent = ( methods ) => ( content ) => {
  const { dispatch } = methods || {};

  if( !dispatch ){
    return;
  }

  dispatch( { type: OVERLAY_ACTIONS.UPDATE_OVERLAY, payload: { content: content } } );
};

/**
 * Represents a composeUpdateOverlay method which trigger dispatch method
 * @param {object} methods - Methods
 * @param {function} methods.dispatch - Overlay reducer dispatch method
 * @returns {updateOverlay}
 */
export const composeUpdateOverlay = ( methods ) => ( content ) => {
  const { dispatch } = methods || {};

  if( !dispatch ){
    return;
  }

  dispatch( { type: OVERLAY_ACTIONS.UPDATE_OVERLAY, payload: content } );
};

/**
 * Represents a composeUpdateBackAction method which trigger dispatch method
 * @param {object} methods - Methods
 * @param {function} methods.dispatch - Overlay reducer dispatch method
 * @returns {updateBackAction} - Update back action callback
 */
export const composeUpdateBackAction = ( methods ) => ( backData, backMethods ) => {
  const { dispatch } = methods || {};

  if( !dispatch ){
    return;
  }

  const { backAction } = backData || {};
  const { callback: backActionCallback } = backMethods || {};
  const payload = { backAction, backActionCallback };

  dispatch( { type: OVERLAY_ACTIONS.UPDATE_BACK_ACTION, payload } );
};

/**
 * Represents a composeUpdateCloseAction method which trigger dispatch method
 * @param {object} modelData - Arguments
 * @param {{ current: object }} modelData.closeActionModel - closeActionModel ref
 * @returns {updateBackAction} - Update back action callback
 */
export const composeUpdateCloseAction = ( modelData ) => ( data, methods ) => {
  const { closeActionModel } = modelData || {};

  if( !closeActionModel?.current ){
    return;
  }

  devLogger( { title: '[OverlayProvider] updating closeAction', value: data } );

  closeActionModel.current = deepmerge( closeActionModel.current, { data, methods } );
};

/**
 * @typedef {object} OverlayConfig
 * @property {string} ariaLabel Aria label
 * @property {object} backAction DXL back action
 * @property {function} backActionCallback Client side callback on back click
 * @property {{ label: string }} closeAccessibilityLabel
 * @property {boolean} crossButtonVisibility crossButtonVisibility show cross button
 * @property {string} customClassName customClassName decorate wrapper with class
 * @property {boolean} displayOverlay enables rendering the overlay,
 * @property {boolean} isSwatchModal
 * @property {boolean} isVideo
 * @property {string} alignment alignment of a flyout
 * @property {JSX.Element} content React element to display in the overlay,
 * @property {string} type type,
 * @property {function} onClose called on Close
 * @property {{current: object}} opener React ref to store element that initiated the overlay
 * @property {object} offsetElement Ref of HTML element to automatically get top offset from
 * @property {boolean} showDynamicFlyoutHeader showDynamicFlyoutHeader display flyout header in OverlayContainer
 * @property {{ width: number, height: number }} videoMeta
 */

/**
 * @type {OverlayConfig}
 */
export const configInitialState = {
  alignment: 'right',
  ariaLabel: undefined,
  backAction: undefined,
  backActionCallback: undefined,
  closeAccessibilityLabel: '',
  crossButtonVisibility: true,
  customClassName: '',
  displayOverlay: false,
  isSwatchModal: false,
  isVideo: false,
  content: undefined,
  onClose: undefined,
  opener: undefined,
  offsetElement: undefined,
  type: undefined,
  showDynamicFlyoutHeader: false,
  videoMeta: ''
};

/**
 * @const {object} OVERLAY_ACTIONS - Overlay reducer action types
 */
export const OVERLAY_ACTIONS = {
  CLOSE_OVERLAY: 'CLOSE_OVERLAY',
  OPEN_OVERLAY: 'OPEN_OVERLAY',
  UPDATE_BACK_ACTION: 'UPDATE_BACK_ACTION',
  UPDATE_OVERLAY: 'UPDATE_OVERLAY'
};

/**
 * Manages OverlayConfig/state
 *
 * @param {object} state Reducer state
 * @param {object} action Reducer action
 * @returns {OverlayConfig}
 */
export const overlayProviderReducer = ( state, action ) => {
  switch ( action.type ){
    case OVERLAY_ACTIONS.OPEN_OVERLAY:
      return utils.handleOpenOverlay( { state, action } );

    case OVERLAY_ACTIONS.CLOSE_OVERLAY:
      return { ...state, ...configInitialState };

    case OVERLAY_ACTIONS.UPDATE_OVERLAY:
    case OVERLAY_ACTIONS.UPDATE_BACK_ACTION:
      return { ...state, ...action.payload };
  }
};

/**
 * Reducer helper for handeling overlay open state
 *
 * @param {object} data arguments
 * @param {object} data.state Reducer state
 * @param {object} data.action Reducer action
 * @returns {OverlayConfig}
 */
export const handleOpenOverlay = ( data ) => {
  const { state, action: { payload = {} } = {} } = handleEmptyObjects( data );

  const {
    action,
    alignment,
    ariaLabel,
    backAction,
    backActionCallback,
    closeAccessibilityLabel,
    content,
    crossButtonVisibility,
    customClassName,
    isSwatchModal,
    isVideo,
    onClose,
    opener,
    offsetElement,
    type,
    videoMeta
  } = payload;

  const openOverlayConfig = {
    alignment,
    ariaLabel,
    backAction,
    backActionCallback,
    closeAccessibilityLabel,
    crossButtonVisibility,
    customClassName,
    displayOverlay: true,
    isSwatchModal,
    isVideo,
    onClose,
    opener,
    offsetElement,
    type,
    videoMeta
  };

  if( content ){
    openOverlayConfig.content = content;
  }
  else if( action ){
    openOverlayConfig.showDynamicFlyoutHeader = action.navigationType === DXL_NAVIGATION_TYPE.Flyout;
    openOverlayConfig.content = (
      <DynamicOverlayComponent action={ action }/>
    );
  }

  return { ...state, ...openOverlayConfig };
};

/**
 * Returns whether the given type is a valid overlay type.
 * @param {string} type
 * @returns {boolean}
 */
export const isOverlay = ( type ) => {
  return type === OVERLAY_TYPE.Modal || type === OVERLAY_TYPE.Flyout;
};

/**
 * @const {string} OVERLAY_SCROLL_BLOCK_CLASS - Overlay scroll block class
 */
export const OVERLAY_SCROLL_BLOCK_CLASS = 'Overlay-Scroll-Block';

/**
 * @const {string} OVERLAY_CLOSE_EVENT - Overlay close event
 */
export const OVERLAY_CLOSE_EVENT = 'OVERLAY_CLOSE_EVENT';

/**
 * @typedef {object} OVERLAY_TYPE
 * @property {'flyout'} Flyout
 * @property {'modal'} Modal
 */

/**
 * @type {OVERLAY_TYPE}
 */
export const OVERLAY_TYPE = {
  Flyout: 'flyout',
  Modal: 'modal'
};

/**
 * @typedef {object} OVERLAY_ALIGNMENT
 * @property {'left'} Left
 * @property {'right'} right
 */

/**
 * @type {OVERLAY_ALIGNMENT}
 */
export const OVERLAY_ALIGNMENT = {
  Left: 'left',
  Right: 'right'
};

/**
 * @typedef {object} openOverlayOptions
 * @property {string} ariaLabel Overlay ariaLabel
 * @property {OVERLAY_TYPE} type Overlay type
 * @property {{ current: object }} opener Open element's React Ref
 * @property {JSX.Element} content React content to display in the overlay
 * @property {boolean} isVideo
 * @property {{ width: number, height: number }} videoMeta Video dimensions meta
 * @property {OVERLAY_ALIGNMENT} alignment
 * @property {{ label: string }} closeAccessibilityLabel ADA label
 * @property {function} onClose called on close
 * @property {current} offsetElement Ref of Element to get top offset from
 * @property {object} action DXL Action
 * @property {boolean} isSwatchModal used for swatch modal, should be deprecated
 * @property {boolean} [crossButtonVisibility=true] show cross button
 * @property {string} customClassName sets a class name on the dialog
 */

/**
 * @callback openOverlay
 * @param {openOverlayOptions} options Overlay opener arguments
 */

/**
 * @callback updateContent
 * @param {JSX.Element} content Update the overlay content
 */

/**
 * @callback updateOverlay
 * @param {JSX.Element} content Update the overlay props
 */

/**
 * @callback updateBackAction
 * @param {object} data - Arguments
 * @param {object} data.backAction - DXL action
 * @param {object} methods - Methods
 * @param {function} methods.backActionCallback - On back action click
 */

/**
 * @callback updateCloseAction
 * @param {object} data - Arguments
 * @param {object} data.action - DXL close action
 * @param {boolean} data.loading - DXL loading state
 * @param {object} methods - Methods
 * @param {function} methods.invokeAction - Layer host invokeAction
 * @param {function} methods.invokeMutation - Layer host invokeMutation
 * @param {function} methods.onBeforeClose - onBeforeClose is invoked before the action is dispatched
 * @param {function} methods.onAfterClose - onAfterClose is invoked after the action is done loading
 * @returns {Promise} Will resolve when loading is done
 */

/**
 * @typedef {object} OverlayContext
 * @property {openOverlay} openOverlay Open overlay function
 * @property {function} closeOverlay Close overlay function
 * @property {updateContent} updateContent Update the overlay content
 * @property {updateOverlay} updateOverlay Update the overlay props
 * @property {updateBackAction} updateBackAction Update the overlay header back action
 * @property {updateCloseAction} updateCloseAction Update the overlay close back action
 * @property {handleOverlayCloseIcon} handleOverlayCloseIcon Event handler for 'X' icon
 */
export const OverlayContext = createContext( {} );

/**
 * @method
 * @returns {OverlayContext}
 */
export const useOverlay = () => useContext( OverlayContext );

export default OverlayProvider;
