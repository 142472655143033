/**
 * It will show the circular spinner for giving user indication that something is happening
 *
 * @module views/components/LoaderCircular
 * @memberof -Common
 */
import './LoaderCircular.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LOADER_CHILD_CLASS, LOADER_FIXED_CLASS } from '@ulta/core/components/Loader/Loader';
/**
 * Represents a Loader component
 *
 * @method
 * @param {LoaderProps} props - React properties passed from composition
 * @returns Loader
 */
export const LoaderCircular = function( props ){
  const fixedClass = props.isFixed ? LOADER_FIXED_CLASS : '';

  return (
    <div className={ classNames( 'Loader-ds', LOADER_CHILD_CLASS, fixedClass ) }>
      <div className={ classNames( {
        'Loader-ds__spinner': props.color,
        [`Loader-ds__spinner--${props.color}`]: props.color
      } ) }
      >
      </div>
    </div>
  );
};

/**
 * Property type definitions
 *
 * @typedef LoaderProps
 * @type {object}
 * @property {string} color - sets the color to the loader
 * @property {string} isFixed - sets loader position to fixed
 */
export const propTypes =  {
  color: PropTypes.string.isRequired,
  isFixed: PropTypes.bool
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} [color='orange-400'] - sets the color to the loader
 */
export const defaultProps =  {
  color: 'orange-400',
  isFixed: false
};

LoaderCircular.propTypes = propTypes;
LoaderCircular.defaultProps = defaultProps;

export default LoaderCircular;
