/**
 * FavoritesUtilityLink for Topbar across the portal
 *
 * @module views/components/FavoritesUtilityLink
 * @memberof -Common
 */
import './FavoritesUtilityLink.scss';

import React, { useCallback, useRef } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

import { Nudge } from '@ulta/components/Nudge/Nudge';

import { useTopBar } from '../TopBar/TopBar';
import * as utils from './FavoritesUtilityLink';

/**
  * Represents a FavoritesUtilityLink component
  *
  * @method
  * @param {FavoritesUtilityLinkProps} props - React properties passed from composition
  * @returns FavoritesUtilityLink
  */
export const FavoritesUtilityLink = React.forwardRef( ( props, _ ) => {
  const { icon, favoritesAction, description, nudge } = props;
  const { displayUserNavigation } = useTopBar();
  const nudgeRef = useRef();
  if( !favoritesAction || !favoritesAction.label || !displayUserNavigation ){
    return null;
  }

  const isButton = favoritesAction.graphql;
  const handleClick = useCallback( utils.composeOnClick( { nudgeRef }, [] ) );

  return (
    <div className='FavoritesUtilityLink'>
      { isButton &&
      <Button
        className='FavoritesUtilityLink__favoriteBtn'
        icon
        clear
        ariaLabel={ description }
        ariaHiddenIcon={ true }
        iconImage={ icon }
        iconSize='xl'
        action={ favoritesAction }
        crossButtonVisibility={ true }
        onClick={ handleClick }
      />
      }
      { !isButton &&
      <Link_Huge
        secondary
        action={ favoritesAction }
        iconImage={ icon }
        iconsize='l'
        ariaLabel={ description }
      />
      }
      { nudge &&
        <Nudge
          ref={ nudgeRef }
          title={ nudge.title }
          message={ nudge.message }
          action={ nudge.action }
          closeAction={ nudge.closeAction }
        />
      }
    </div>
  );
} );

/**
 * Close nudge on icon click
 * @function composeOnClick
 * @param {object} methods - Object containing nudgeRef method
 * @param {function} methods.nudgeRef - Method to set nudgeRef state
 */

export const composeOnClick = ( methods ) => () => {
  const { nudgeRef } = methods || {};
  if( !nudgeRef.current ){
    return;
  }
  nudgeRef.current.handleCloseNudge();
};

/**
  * Property type definitions
  * @typedef FavoritesUtilityLinkProps
  * @type {object}
  * @property {string} icon - sets the icon
  * @property {object} favoritesAction - sets the action
  * @property {string} description - content for aria label on favorites icon
  */
export const propTypes = {
  icon: PropTypes.string.isRequired,
  favoritesAction: PropTypes.object,
  description: PropTypes.string,
  nudge: PropTypes.object
};

FavoritesUtilityLink.propTypes = propTypes;
FavoritesUtilityLink.displayName = 'FavoritesUtilityLink';

export default FavoritesUtilityLink;

