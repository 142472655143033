/**
 * AfterPay should be added to enable payment on installment basis for a particular price range
 *
 * @module views/components/AfterPay
 * @memberof -Common
 */
import './AfterPay.scss';

import React from 'react';

import PropTypes from 'prop-types';

import JSscriptTag from '@ulta/core/components/JSscriptTag/JSscriptTag';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { formatLocale, getApplicationLocale } from '@ulta/core/utils/locale/locale';

/**
 * Represents AfterPay component
 *
 * @method
 * @param {AfterPayProps} props - React properties passed from composition
 * @returns AfterPay
 */
export const AfterPay = function( props ){
  const userLocale = formatLocale( getApplicationLocale( props.locale, isServer() ) );
  const { product } = props;
  return (
    <>
      { product &&
      <div className='AfterPay'>
        <JSscriptTag
          tagId='AfterPay'
          src='https://js.afterpay.com/afterpay-1.x.js'
          dataSet={ [{ 'analyticsEnabled':'true' }, { 'min':'35.00' }] }
          defer={ true }
          immediate={ false }
        />

        <afterpay-placement
          data-is-eligible={ product.eligible ? 'true' : 'false' }
          data-locale={ userLocale }
          data-currency={ product.currency }
          data-amount={ product.price }
          data-show-upper-limit='false'
          data-size='xs'
          data-logo-type='lockup'
          data-lockup-theme='black'
        >
        </afterpay-placement>

      </div>
      }
    </>
  );
};

/**
 * Property type definitions
 * @typedef AfterPayProps
 * @type {object}
 * @property {object} product - Sets the currency, amount, eligibility
 * @property {string} locale - Sets the data-locale field
 */

export const propTypes =  {
  /** Sets the currency, eligibility, and amount label to display */
  product: PropTypes.object,
  /** Sets the data-locale prop */
  locale: PropTypes.string
};

AfterPay.propTypes = propTypes;

export default AfterPay;