/* eslint-disable no-console */
import './Nudge.scss';

import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Markdown from '@ulta/core/components/Markdown/Markdown';
import Text from '@ulta/core/components/Text/Text';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { getTtlStorage, setTtlStorage } from '@ulta/core/utils/storage/storage';

import * as utils from './Nudge';

/**
 * Represents a Nudge component
 *
 * @method
 * @returns Nudge
 */
export const Nudge = forwardRef( ( props, ref ) => {
  const [showNudge, setShowNudge] = useState( false );
  const { title, action, message, closeAction } = props;

  const handleSetStorage = useCallback( utils.composeHandleSetStorage( { setTtlStorage } ), [setTtlStorage] );
  const handleCloseNudge = useCallback( utils.composeCloseNudge( { setShowNudge, handleSetStorage } ), [setShowNudge, handleSetStorage] );
  const handleShowNudge = useCallback( utils.composeShowNudge( { setShowNudge, getTtlStorage, handleSetStorage } ), [setShowNudge, getTtlStorage, handleSetStorage] );

  if( ref !== null ){
    // eslint-disable-next-line no-param-reassign
    ref.current = { handleCloseNudge };
  }

  useEffect( () => {
    handleShowNudge();
  }, [] );

  if( !title || !showNudge || isServer() ){
    return null;
  }

  return (
    <div className='Nudge'
      role='tooltip'
      aria-label='sign in'
    >
      <div className='Nudge__wrapper'>
        <div className='Nudge__content'>
          <Text
            textStyle='body-3'
            htmlTag={ title?.htmlTag }
            color='black'
            fontWeight='bold'
          >
            { title?.text }
          </Text>
          <Markdown
            alignment={ message?.alignment }
            bodyStyle='body-3-noPadding'
            cmsMarkdownContent={ message?.body }
          />
          <Button
            likeLink={ true }
            className='Nudge__action'
            action={ action }
            onClick={ handleCloseNudge }
          >
            { action?.label }
          </Button>
        </div>
        <div className='Nudge__arrow'></div>
        <Button
          className='Nudge__close'
          variant='unstyled'
          icon
          ariaLabel={ closeAction?.label }
          iconSize='lg'
          iconImage={ closeAction?.label }
          tertiary={ true }
          action={ closeAction }
          onClick={ handleCloseNudge }
        />
      </div>
    </div>
  );
} );

/**
 * Returns a function that closes the nudge and sets storage for 3 days.
 *
 * @param {object} methods - Object containing setShowNudge and handleSetStorage methods
 * @param {function} methods.setShowNudge - Method to set showNudge state
 * @param {function} methods.handleSetStorage - Method to set storage value
 *
 * @return {function} - Function that closes the nudge and sets storage
 */
export const composeCloseNudge = ( methods ) => () => {
  const { setShowNudge, handleSetStorage } = methods || {};

  if( !setShowNudge || !handleSetStorage ){
    return;
  }

  handleSetStorage();
  setShowNudge( false );
};

/**
 * Show nudge if it has expired
 * @function composeShowNudge
 * @param {object} methods - Object containing setShowNudge, getTtlStorage, and handleSetStorage methods
 * @param {function} methods.setShowNudge - Method to set showNudge state
 * @param {function} methods.getTtlStorage - Method to retrieve storage value
 * @param {function} methods.handleSetStorage - Method to set storage value
 * @returns {function} Function to show nudge if it has expired
 */
export const composeShowNudge = ( methods ) => () => {
  const { setShowNudge, getTtlStorage, handleSetStorage } = methods || {};

  if( !setShowNudge || !getTtlStorage || !handleSetStorage ){
    return;
  }

  const isNudgeVisible =  getTtlStorage( { componentKey: 'Nudge', secure: true } )?.isExpired !== false;
  setShowNudge( isNudgeVisible );
  handleSetStorage();
};

/**
 * Closes the nudge and sets storage to expire in 3 days
 * @param {object} methods - Object containing setTtlStorage method
 * @param {function} methods.setTtlStorage - Method to set storage value
 * @returns {function} Function to close nudge and set storage
 */
export const composeHandleSetStorage = ( methods ) => () => {
  const { setTtlStorage } = methods || {};

  if( !setTtlStorage ){
    return;
  }
  const threeDays = 3 * 24 * 60 * 60 * 1000;
  setTtlStorage( { componentKey: 'Nudge', value: {}, ttl: threeDays, secure: true } ) || {};
};

/**
 * Property type definitions
 * @typedef Nudge
 * @type {object}
 * @param {Object} props - The props for the Nudge component.
 * @param {object} props.t - The title of the nudge.
 * @param {object} props.message - The message of the nudge.
 * @param {object} props.action - The action of the nudge.
 * @param {object} props.closeAction - The CloseAction of the nudge.
 * @returns {JSX.Element|null} The Nudge component or null if no title is provided.
 */
export const propTypes = {
  title: PropTypes.object,
  message: PropTypes.object,
  action: PropTypes.object,
  closeAction: PropTypes.object
};

Nudge.propTypes = propTypes;

Nudge.displayName = 'Nudge';

export default Nudge;
