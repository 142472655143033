/**
 * Button Component
 * @module views/Atoms/Button
 */
import './Button.scss';

import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import PaletteButton from 'web-palette/dist/components/Button/Button';

import { useLocalRef } from '@ulta/core/hooks/useLocalRef/useLocalRef';
import { useConditionalView } from '@ulta/core/providers/ConditionalViewProvider/ConditionalViewProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { processClientActionType } from '@ulta/core/utils/clientActionProcessor/clientActionProcessor';
import { constants } from '@ulta/core/utils/constants/constants';
import datacapture from '@ulta/core/utils/datacapture/datacapture';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

/**
 * Represents a Button component
 * @method
 * @param {ButtonProps} props - React properties passed from composition
 * @param {Object} ref - React forwardRef passed to the button for parent control if needed
 * @returns Button
 */
const Button = forwardRef( ( props, ref ) => {
  const {
    dataIndex,
    disabled,
    onMouseDown,
    disabledHandler,
    tabIndex,
    type,
    label,
    children,
    className,
    // ADA props
    ariaControls,
    ariaHidden,
    ariaCurrent,
    ariaPressed,
    ariaLabel,
    isExpanded,
    iconLabel,
    ariaHiddenIcon,
    // overlay related props
    alignment,
    overlayContent,
    isSwatchModal,
    crossButtonVisibility,
    offsetElement,
    overlayAriaLabel,
    overlayCustomClass,
    // palette props (preferred)
    variant,
    size,
    iconPosition,
    iconOnly,
    iconSize,
    iconName,
    fullWidth,
    // legacy props (deprecated)
    primary,
    clear,
    compact,
    tiny,
    tertiary,
    icon,
    secondary,
    likeLink,
    linkCompact,
    compactLink,
    withHover,
    iconImage,
    iconRight,
    unstyled
  } = props;

  const { action, onClick } = handleEmptyObjects( props );
  const { openOverlay, closeOverlay, isOverlay } = useOverlay();
  const buttonRef = useLocalRef( { ref } );
  const displayOverlay = action && isOverlay?.( action.navigationType );
  const { setViewType }  = useConditionalView();

  const displayProps = getDisplayProps( props );
  const PalButton = PaletteButton.default || PaletteButton; // TODO: figure out why we need to find default in the PaletteButton and why import { default as Palette Button } isn't working.

  return (
    <>
      <PalButton
        disabled={ disabled }
        label={ label }
        { ...displayProps }
        tabIndex={ tabIndex }
        onClick={ disabled ? disabledHandler : Button.handleOnClick( { action }, { closeOverlay, onClick, setViewType } ) }
        onMouseDown={ disabled ? disabledHandler : onMouseDown }
        screenReaderText={ariaLabel}
        { ...( !isNaN( dataIndex ) && { 'data-index': dataIndex } ) }
        { ...( ariaCurrent && { 'aria-current': ariaCurrent } ) }
        { ...( ariaControls && { 'aria-controls': ariaControls } ) }
        { ...( ariaHidden && { 'aria-hidden': ariaHidden } ) }
        buttonRef={ buttonRef }
        type={ type }
        aria-expanded={ isExpanded }
        aria-pressed={ ariaPressed }
        className={ className }
        { ...( displayOverlay && {
          onClick: ( e ) => {
            handleOnClick( { action }, { closeOverlay, onClick, setViewType } )( e );
            openOverlay( {
              type: action.navigationType,
              opener: buttonRef,
              alignment: alignment,
              crossButtonVisibility: crossButtonVisibility !== false,
              ...( !overlayContent && action && { action: action } ),
              ...( overlayContent && { content: overlayContent } ),
              ...( isSwatchModal && { isSwatchModal: isSwatchModal } ),
              ...( offsetElement && { offsetElement: offsetElement } ),
              ...( overlayAriaLabel && { ariaLabel: overlayAriaLabel } ),
              ...( overlayCustomClass && { customClassName: overlayCustomClass } )
            } );
          }
        } ) }
      >
        { children }
      </PalButton>
    </>
  );
} );


/**
 * A click handler for a the disabled button
 * @method
 * @param {Object} event
 * @returns false
 */
export const disabledHandler = ( event ) => {
  event.preventDefault();
  return false;
};

/**
 * Function to format legacy button props to palette button props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { Object } // object to spread into the palette button
 */
export const getDisplayProps = ( data ) => {
  const {
    icon,
    iconOnly,
    fullWidth
  } = data;

  const buttonIconPosition = getIconPosition( data );
  const buttonIconName = getIconName( data );
  const buttonSize = getButtonSize( data );
  const buttonVariant = getButtonVariant( data );
  const derivedIconSize = getIconSize( data );
  const isIconOnly = icon || iconOnly;
  const isFullWidth =  fullWidth;

  return (
    {
      variant: buttonVariant,
      size: buttonSize,
      fullWidth: isFullWidth,
      iconOnly: isIconOnly,
      iconPosition: buttonIconPosition,
      iconName: buttonIconName,
      iconSize: derivedIconSize
    }
  );
};


/**
 * Function to format legacy variant props to palette button props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for button variant
 */
export const getButtonVariant = ( data ) => {
  const {
    variant,
    secondary,
    tertiary,
    likeLink,
    linkCompact
  } = data;

  const noStyle = !variant && !secondary && !tertiary && !likeLink && !linkCompact;

  if( noStyle ){
    return 'primary'; // default style is primary
  }

  if( !!variant ){
    return variant;
  }
  if( secondary ){
    return 'secondary';
  }
  if( tertiary ){
    return 'tertiary';
  }
  if( likeLink ){
    return 'link';
  }
  if( linkCompact ){
    return 'linkSecondary';
  }
};


/**
 * Function to format legacy iconName props to palette button props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for icon name
 */
export const getIconName = ( data ) => {
  const {
    iconName, // palette prop (preferred)
    iconImage
  } = data;

  if( !iconName && !iconImage ){
    return ''; // default iconName is no icon name
  }

  if( !!iconName ){
    return iconName;
  }
  if( !iconName && !!iconImage ){
    return iconImage;
  }
};

/**
 * Function to format legacy button size props to palette button props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for button size
 */
export const getButtonSize = ( data ) => {
  const {
    size,
    compact,
    tiny
  } = data;

  if( !size && ( !compact && !tiny ) ){
    return 'default'; // default size is default
  }
  if( !!size ){
    return size;
  }
  if( compact ){
    return 'compact';
  }
  if( tiny ){
    return 'tiny';
  }
};

/**
 * Function to format legacy icon size props to palette icon size props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for icon size
 */
export const getIconSize = ( data ) => {
  const { iconSize } = data;

  // legacy icon sizes are 's', 'm', and 'l'. Palette icon sizes are 'default', 'lg', and 'xl'. We want to convert lagacy sizes to the palette sizes.
  if( !iconSize || iconSize === 's' || iconSize === 'default' ){
    return 'default';
  }
  if( iconSize === 'm' || iconSize === 'lg' ){
    return 'lg';
  }
  if( iconSize === 'l' || iconSize === 'xl' ){
    return 'xl';
  }

  // return default for unsupported values
  return 'default';
};

/**
 * Function to format legacy button icon position props to palette button props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for icon position
 */
export const getIconPosition = ( data ) => {
  const {
    iconPosition, // palette prop (preferred)
    iconImage,
    icon,
    iconRight
  } = data;

  if( !iconPosition && ( !iconImage && icon ) ){
    return 'before'; // default icon position is before
  }

  if( !!iconPosition ){
    return iconPosition; // prefer palette props
  }

  // if legacy icon name exists and palette icon name does not exist, we will get the position based on the legacy boolean
  if( iconImage && !icon ){
    if( iconRight ){
      return 'after'; // format to palette accepted string
    }
    if( !iconRight ){
      return 'before'; // format to palette accepted string
    }
  }

  // return default for unsupported values
  return 'before';
};

/**
 * Click handler
 * @param {ButtonProps} props
 */
export const handleOnClick = ( data, methods ) => {
  return function( e ){
    const { action = {} } = handleEmptyObjects( data );
    const { closeOverlay, onClick, setViewType } = methods || {};

    const { dataCaptureData, clientActionType } = action;

    if( dataCaptureData ){
      datacapture.processEvents( { dataCapture: { ...dataCaptureData } }, constants.DATACAPTURE_EVENT_TYPE.click );
    }

    onClick?.( e );

    if( clientActionType ){
      processClientActionType( { action }, { closeOverlay, setViewType } );
    }
  };
};

Button.displayName = 'Button';

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} ariaLabel='' - Default ariaLabel
 * @property {string} className='' - Default className
 * @property {boolean} disabled=false - Default disabled variation
 * @property {function} onClick=null - Default onClick
 * @property {string} type='button' - Default type
 * @property {function} onMouseDown=null - Default onMouseDown
 * @property {boolean} crossButtonVisibility=false - Default crossButtonVisibility
 * @property {boolean} ariaHiddenIcon - sets the aria hidden
 */
export const defaultProps = {
  ariaLabel: '',
  className: '',
  disabled: false,
  onClick: null,
  type: 'button',
  onMouseDown: null,
  crossButtonVisibility: true,
  ariaHiddenIcon: false,
  alignment: 'center'
};

/**
 * property type definitions
 * @typedef ButtonProps
 * @type {object}
 * @property {number} dataIndex - TODO: update description
 * @property {boolean} disabled - Flag to render the disabled variation
 * @property {function} onMouseDown - Function as the event handler for mouse down
 * @property {function} disabledHandler - functon to handle the disabled event(s)
 * @property {number} tabIndex - flag to add or remove the button from the tab order
 * @property {string} type - Type of button
 * @property {object} action - Set the label and graphql
 * @property {string} label - Button text to render as child
 * @property {object} children - objects to render and children of button
 * @property {string} className - Custom CSS classes names to apply
 * @property {function} onClick - Function as the event handler
 *
 * @property {string} ariaControls - aria-control for ADA perspective
 * @property {boolean} ariaHidden - aria control to hide/show the button from screen readers
 * @property {string} ariaCurrent - aria control to set the attribute for aria current
 * @property {string} ariaLabel - Text for the screen readers
 * @property {boolean} isExpanded - indicates if the control is expanded or collapsed
 * @property {string} iconLabel - TODO: Name of icon used for accessibility
 * @property {boolean} ariaHiddenIcon - TODO: Flag to announce the Icon for accessibility
 *
 * @property {string} alignment - Alignment for overlay modal/flyout
 * @property {object} overlayContent - content for overlay modal/flyout
 * @property {boolean} isSwatchModal - Flag to set class for swatch details
 * @property {boolean} crossButtonVisibility - Flag to set class for modal/flyout x button
 * @property {object} offsetElement - TODO: update this
 * @property {string} overlayAriaLabel - aria label for a flyout trigger by the button
 * @property {string} overlayCustomClass - custom class applied to a flyout triggered by the button
 *
 * @property {string} variant - variant of button
 * @property {string} size - size of button
 * @property {string} iconPosition - position of icon ( 'before' or 'after' )
 * @property {boolean} iconOnly - flag to render an button that displays the icon without a label
 * @property {string} iconSize - size of the palette icon ( 'default' , 'lg', or 'xl' )
 * @property {string} iconName - name of the icon
 * @property {boolean} fullWidth - Flag to render the full width button
 *
 * @property {boolean} primary - [DEPRECATED] Flag to render the Outline button
 * @property {boolean} clear - [DEPRECATED] Flag to render the Icon circle with clear variation
 * @property {boolean} compact - [DEPRECATED] Flag to render the compact variation
 * @property {boolean} tiny - [DEPRECATED] Flag to render the tiny variation
 * @property {boolean} tertiary - [DEPRECATED] Flag to render the tertiary button
 * @property {boolean} icon - [DEPRECATED] Flag to render the icon only variation
 * @property {boolean} secondary - [DEPRECATED] Flag to render the Outline button
 * @property {boolean} likeLink - [DEPRECATED] Flag to make the button have the styles of a Link
 * @property {boolean} linkCompact - [DEPRECATED] Flag to make the button have a compact variation and styles of a Link
 * @property {boolean} compactLink - [DEPRECATED] Flag to make the button have a compact variation and styles of a Link
 * @property {boolean} withHover - [DEPRECATED] Flag to render the withHover variation
 * @property {string} iconImage - [DEPRECATED] Name of the Icon to render, renders the Icon
 * @property {boolean} iconRight - [DEPRECATED] Flag to render the icon to the right of the label
 * @property {boolean} unstyled - [DEPRECATED] Flag to render the unstyled button
 */

/**
 * @type {ButtonProps}
 */
export const propTypes = {
  /** TODO: update description */
  dataIndex: PropTypes.number,
  /** Flag to render the disabled variation */
  disabled: PropTypes.bool,
  /** Text for the screen readers */
  ariaLabel: PropTypes.string,
  /** id to control the aria , for ADA https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-controls */
  ariaControls: PropTypes.string,
  /** Custom CSS class names to apply */
  className: PropTypes.string,
  /** Flag to render the Icon circle with clear variation */
  clear: PropTypes.bool,
  /** Flag to render the compact variation */
  compact: PropTypes.bool,
  /** Flag to make the button have a compact variation and the styles of a Link */
  compactLink: PropTypes.bool,
  /** Flag to render the tiny variation */
  tiny: PropTypes.bool,
  /** Flag to render the withHover variation */
  withHover: PropTypes.bool,
  /** Flag to render the icon only variation */
  icon: PropTypes.bool,
  /** Name of the Icon to render, renders the Icon */
  iconImage: PropTypes.string,
  /** Name of icon used for accessibility */
  iconLabel: PropTypes.string,
  /** Flag to render the icon to the right of the label */
  iconRight: PropTypes.bool,
  /** Size of the Icon to render, it's value matches the viewports naming: s, m, l */
  iconSize: PropTypes.string,
  /** Flag to make the button have the styles of a Link */
  likeLink: PropTypes.bool,
  /** Text to render as child */
  label: PropTypes.oneOfType( [PropTypes.string, PropTypes.element] ),
  /** Function as the event handler */
  onClick: PropTypes.func,
  /** Flag to render the secondary button */
  secondary: PropTypes.bool,
  /** Flag to render the tertiary button */
  tertiary: PropTypes.bool,
  /** Type of button */
  type: PropTypes.string,
  /** Function as the event handler for mouse down*/
  onMouseDown: PropTypes.func,
  /** Flag to decide rendering of unstyled button */
  unstyled: PropTypes.bool,
  /** Flag to decide rendering of fullWidth button */
  fullWidth: PropTypes.bool,
  /** Flag to set class for swatch modal */
  isSwatchModal: PropTypes.bool,
  /** Flag to set class for modal/flyout x button */
  crossButtonVisibility: PropTypes.bool,
  /** Alignment for overlay modal/flyout */
  alignment: PropTypes.string,
  /** Content for overlay modal/flyout */
  overlayContent: PropTypes.object,
  /** get actions like label, graphql, navigationType */
  action: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string,
    dataCaptureData: PropTypes.object,
    clientActionType: PropTypes.oneOfType( [PropTypes.object, PropTypes.string] ),
    navigationType: PropTypes.oneOfType( [PropTypes.object, PropTypes.string] )
  } ),
  /** Flag To Announce the Icon for accessibility */
  ariaHiddenIcon: PropTypes.bool,
  variant: PropTypes.string,
  iconOnly: PropTypes.bool
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
Button.handleOnClick = handleOnClick;

export default Button;
