/**
 * This is used as the Item for the LargeDealCard.
 *
 * @module views/components/LargeDealCard
 * @memberof -Common
 */
import './LargeDealCard.scss';

import React, {useEffect, useMemo, useRef} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import ActionGroup from '@ulta/modules/ActionGroup/ActionGroup';
import Tag from '@ulta/modules/Tag/Tag';

import { constants } from '@ulta/utils/constants/constants';

import IconWithTitles from '../IconWithTitles/IconWithTitles';
import StatusBadges from '../StatusBadges/StatusBadges';
import * as utils from './LargeDealCard';
import {moveScreenReaderFocus} from "@ulta/utils/accessibility/accessibility.js";

/**
 * Represents a LargeDealCard component
 *
 * @method
 * @param {LargeDealCardProps} props - React properties passed from composition
 * @returns LargeDealCard
 */
export const LargeDealCard = function( props ){
  const largeDealCardRef = useRef( null );
  const offerActivateMessageRef = useRef( null );
  const {
    action1,
    action1Style,
    action2,
    action2Style,
    description,
    eyebrow,
    headline,
    image,
    isOfferActivated,
    isUltamateRewards,
    maxWidthAspectRatio,
    root,
    rootMargin,
    tag,
    threshold,
    noOffers,
    activatedMessage,
    activateAction,
    activatedLabel,
    invokeMutation,
    offerId,
    vertical
  } = props;

  const displayMessage = ( activatedMessage ) || ( vertical && activatedLabel && isOfferActivated );
  const displayAction = ( ( action1?.label ) || ( action2?.label ) );

  if( action1?.navigationType === DXL_NAVIGATION_TYPE.Flyout ){
    action1.crossButtonVisibility = true;
  }
  if( action2?.navigationType === DXL_NAVIGATION_TYPE.Flyout ){
    action2.crossButtonVisibility = true;
  }

  useIntersectionObserver( largeDealCardRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  useEffect( () => {
    if( !!offerActivateMessageRef ){
      // Accessibility change
      moveScreenReaderFocus( offerActivateMessageRef.current );
    }
  }, [] );

  const aspectRatio = useMemo( () => utils.getAspectRatio( { maxWidthAspectRatio } ), [maxWidthAspectRatio] );

  return (
    <div className={
      classNames( 'LargeDealCard', {
        'LargeDealCard__rewardClass'  : isUltamateRewards
      } )
    }
    ref={ largeDealCardRef }
    >
      <div className={
        classNames( 'LargeDealCard__image', {
          'LargeDealCard__rewardImage' : isUltamateRewards
        } )
      }
      >
        <UltaAsset { ...image }
          { ...( !isUltamateRewards && { aspectRatio } ) }
        />
      </div>
      <div className={
        classNames( 'LargeDealCard__textContent', {
          'LargeDealCard__rewardTextContent' : isUltamateRewards
        } )
      }
      >
        { tag && (
          <div className='LargeDealCard__tag'>
            <Tag
              message={ tag }
              backgroundColor='content-brand-02-strong' // Palette DXL setting - will be refactored with new component
              textColor='content-knockout' // Palette DXL setting - will be refactored with new component
            />
          </div>
        ) }
        { eyebrow && (
          <div className='LargeDealCard__eyebrow'>
            <Text htmlTag='p'
              textStyle='eyebrow'
            >
              { eyebrow }
            </Text>
          </div>
        ) }
        { headline && (
          <div className='LargeDealCard__headline'>
            <Text
              htmlTag='h3'
              textStyle='title-5'
            >
              { headline }
            </Text>
          </div>
        ) }
        { description && (
          <div className='LargeDealCard__subtitle'>
            <Text htmlTag='p'
              textStyle='body-2'
              color='neutral-800'
            >
              { description }
            </Text>
          </div>
        ) }
        { noOffers && (
          <div className='LargedealCard__NoOffers'>
            <IconWithTitles { ...props }
              headline={ props.header }
            />
          </div>
        ) }
        { displayAction && !vertical && (
          <div className='LargeDealCard__actions'>
            <ActionGroup
              action1={ action1 }
              action2={ action2 }
              action1Style={ action1Style }
              action2Style={ action2Style }
              isOfferActivated={ isOfferActivated }
            />
          </div>
        ) }

        { vertical && action2?.label && (
          <div className='LargeDealCard__actions'>
            <Link_Huge
              likeButtonOutline
              likeButtonWithHover
              likeButtonCompact
              target='_self'
              action={ action2 }
            >
              { action2.label }
            </Link_Huge>
          </div>
        ) }

        { displayMessage &&
          <div className='LargeDealCard__activatedMessage'
            tabIndex='-1'
            ref={ offerActivateMessageRef }
          >
            <StatusBadges
              badgeType='verification'
              badgeValue='Svg16Available'
            />
            <Text
              textStyle='body-3'
            >
              { activatedMessage || activatedLabel }
            </Text>
          </div>
        }
        { ( activateAction && !isOfferActivated ) && (
          <div className='LargeDealCard__actions'>
            <Button
              compact
              action={ activateAction }
              label={ activateAction.label }
              onClick={ ( ) => {
                utils.handleSubmit( { activateAction, offerId }, { invokeMutation } );
              } }
            />
          </div>
        ) }
      </div>
    </div>
  );
};

/**
 * Provide aspectRatio for passing down to UltaAsset as string
 */


export const getAspectRatio = ( data ) => {
  const { maxWidthAspectRatio } = data || {};
  return `${Number( maxWidthAspectRatio ) || 1}:1`;
};

/**
* A click handler function for mutation call
* @method
* @param {Object} methods - Passing methods as an argument for the mutation call
* @param {Object} data - Passing data as an argument
*/
export const handleSubmit = ( data, methods ) => {

  const { invokeMutation } = methods || {};
  const { activateAction, offerId } = data || {};
  const { params, customHeaders, graphql } = activateAction || {};

  if( !graphql || !invokeMutation ){
    return;
  }

  invokeMutation( {
    graphql,
    customHeaders,
    params,
    variables: {
      url: { path: !isServer() && global.location.pathname },
      moduleParams: {
        offerId
      }
    }
  } );
};


/**
 * Property type definitions
 *
 * @typedef LargeDealCardProps
 * @type {object}
 * @property {object} image - Set the Image for the LargeDealCard
 * @property {string} tag - Set the tag for the LargeDealCard
 * @property {string} eyebrow - Set the eyebrow for the LargeDealCard
 * @property {string} headline - Set the headline for the LargeDealCard
 * @property {string} description - Set the description for the LargeDealCard
 * @property {object} action1 - Set the action1 for the LargeDealCard
 * @property {object} action2 - Set the action2 for the LargeDealCard
 * @property {string} action1Style - Set the action1Style
 * @property {string} action2Style - Set the action2Style
 * @property {boolean} isUltamateRewards - Sets the className for the UltamateRewardsOffer
 * @property {boolean} isOfferActivated - Sets the isOfferActivated for the LargeDealCard
 * @property {number} maxWidthAspectRatio - Sets the maxHeight for the Images aspectRatio
 * @property {boolean} noOffers - Sets the flag to display the state for No Offers
 * @property {string} activatedMessage - Sets the string value when the offer is activated
 * @property {object} activateAction - Sets the object to set the activateAction
 * @property {function} invokeMutation - Sets the function for invokeMutation
 * @property {boolean} vertical - Sets the layout for large deal card
 */
export const propTypes = {
  image: PropTypes.object,
  tag: PropTypes.string,
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  action1: PropTypes.object,
  action2: PropTypes.object,
  action1Style: PropTypes.string,
  action2Style: PropTypes.string,
  isUltamateRewards: PropTypes.bool,
  isOfferActivated: PropTypes.bool,
  maxWidthAspectRatio: PropTypes.number,
  noOffers: PropTypes.bool,
  activatedMessage: PropTypes.string,
  activateAction: PropTypes.object,
  invokeMutation: PropTypes.func,
  vertical: PropTypes.bool
};

/**
  * Default values for passed properties
  * @type {object}
  */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};
LargeDealCard.propTypes = propTypes;

export default LargeDealCard;
