/**
 *  This component will help to users to see the product for which they are checking the store availability.
 *
 * @module views/components/FindInStoreProduct
 * @memberof -Common
 */
import './FindInStoreProduct.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Image from '@ulta/core/components/Image/Image';
import Text from '@ulta/core/components/Text/Text';

import ProductDimension from '../ProductDimension/ProductDimension';

/**
 * Represents a FindInStoreProduct component
 *
 * @method
 * @param {FindInStoreProductProps} props - React properties passed from composition
 * @returns FindInStoreProduct
 */
export const FindInStoreProduct = function( props ){
  const {
    brandName,
    productName,
    typeLabel,
    valueLabel,
    imageUrl,
    imageWidth
  } = props;

  if( !brandName || !productName || !imageUrl ){
    return null;
  }

  return (
    <div className='FindInStoreProduct'>
      <div className='FindInStoreProduct__LeftColumn'>
        <Image src={ imageUrl }
          width={ imageWidth }
        />
      </div>
      <div className='FindInStoreProduct__RightColumn'>
        <Text
          textStyle='body-3'
          color='neutral-500'
        >
          { brandName }
        </Text>
        <Text
          htmlTag='h2'
          textStyle='body-3'
        >
          { productName }
        </Text>
        <ProductDimension
          type={ typeLabel }
          description={ valueLabel }
        />
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef FindInStoreProductProps
 * @type {object}
 * @property {string} brandName - Sets the brand name string for product
 * @property {string} productName - Sets the product name string for product
 * @property {string} typeLabel - This is the type of label for product
 * @property {string} valueLabel - This is the value of label for product
 * @property {string} imageUrl - This is the image url for product
 * @property {imageWidth} imageWidth - This is the image width
 */
export const propTypes = {
  /** This is the brand name for product */
  brandName: PropTypes.string.isRequired,
  /** This is the product name for product */
  productName: PropTypes.string.isRequired,
  /** This is the label type for product */
  typeLabel: PropTypes.string.isRequired,
  /** This is the label value for product */
  valueLabel: PropTypes.string.isRequired,
  /** This is the image url for product */
  imageUrl: PropTypes.string.isRequired,
  /** This is the image width for product */
  imageWidth: PropTypes.number
};

FindInStoreProduct.propTypes = propTypes;

export default FindInStoreProduct;
