/**
 * This is the swatch component.It will be used to diplay variant available for both color and amount type. This is a part of SHOP for now but can be used other than shop
 *
 * @module views/components/Swatch
 * @memberof -Common
 */
import './Swatch.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

import * as utils from './Swatch';

/**
 * Represents a Swatch component
 *
 * @method
 * @param {SwatchProps} props - React properties passed from composition
 * @returns Swatch
 */
export const Swatch = function( props ){
  const { selected, disabled, unavailable, width, height, alt, src, colorCode, onSwatchClick, selectAction, linkSelectAction = {}, isHover, withHoverAndSelected } = props;

  if( !colorCode && !src ){
    return null;
  }

  const ariaCurrent = selected ? 'true' : 'false';

  const variantSwatchColorCode = (
    <span className='Swatch__div'
      style={ { background: colorCode } }
    ></span>
  );

  const variantSwatchImage = (
    <span className='Swatch__image'>
      <img src={ utils.getSwatchImage( { src, width } ) }
        alt={ alt }
        height={ height }
        width={ width }
      />
    </span>
  );

  const swatchImage = colorCode ? variantSwatchColorCode : variantSwatchImage;

  const variantButton = (
    <Button
      type='button'
      onClick={ disabled ? disabledHandler : onSwatchClick }
      disabled={ disabled }
      variant='unstyled'
      action={ selectAction }
      ariaCurrent={ ariaCurrent }
    >
      { swatchImage }
    </Button>
  );

  const variantLink = (
    <Link_Huge
      disabled={ disabled }
      action={ linkSelectAction }
      ariaCurrent={ ariaCurrent }
    >
      { swatchImage }
    </Link_Huge>
  );

  const variantImage = (
    <>{ swatchImage }</>
  );

  let swatchElement;
  if( onSwatchClick ){
    swatchElement = linkSelectAction?.url ? variantLink : variantButton;
  }
  else {
    swatchElement = variantImage;
  }
  return (
    <span
      className={ classNames( 'Swatch Swatch__circle', {
        'Swatch__circle--selected': withHoverAndSelected && selected,
        'Swatch__circle--disabled': disabled,
        'Swatch__circle--unavailable': unavailable,
        'Swatch__withHoverAndSelected--isHover': isHover,
        'Swatch__withHoverAndSelected' : withHoverAndSelected
      } ) }
    >
      { swatchElement }
    </span>
  );
};

/**
 * Get swatch image src at 2x width/height, reduces on bandwidth as original images ar 1000x1000
 * @param {object} data
 * @param {string} data.src image src
 * @param {number} data.width image width
 * @return {string} image src @ 2x
 */
export const getSwatchImage = ( data ) => {
  const { src, width } = data || {};
  if( !src ){
    return;
  }
  const mark = src.includes( '?' ) ? '&' : '?';
  const withFmt = src.includes( 'fmt' ) ? src : `${src}${mark}fmt=auto`;

  if( !width || src.includes( 'w=' ) ){
    return withFmt;
  }

  return `${src}${mark}w=${width * 2}&fmt=auto`;
};

/**
 * A click handler for the disabled button
 * @method
 * @param {object} event
 * @returns false
 */
export const disabledHandler = ( event ) => {
  event.preventDefault();
  return false;
};

/*
 * Object containing color codes that we need business logic around
 * @constant {object}
 */
export const CUSTOM_SWATCH_CODES = {
  multi: 'multi',
  clear: 'clear'
};

/**
 * Sanitizes props for Swatch
 * @param {object} props Swatch props
 * @param {string} props.colorCode filter colorCode
 * @param {object} props.colorImage UltaAsset for image
 * @param {object} props.colorImageDimensions UltaAsset for image dimesions
 */
export const reduceSwatchProps = ( props ) => {
  const { colorCode = '', colorImage = {}, colorImageDimensions = {} } = { ...props };
  if( colorImage?.imageUrl ){
    return { src: colorImage.imageUrl, height: colorImageDimensions.height, width: colorImageDimensions.width };
  }
  else if( colorCode ){
    return { colorCode };
  }
  return null;
};

/**
 * Property type definitions
 * @typedef SwatchProps
 * @type {object}
 * @property {string} src - Sets the image src for swatch display
 * @property {string} alt - Sets the alt text for swatch.
 * @property {boolean} selected - Sets the swatch as selected if true and vice versa
 * @property {number} width - Sets width of the swatch
 * @property {number} height - Sets height of the swatch
 * @property {boolean} disabled - Sets the swatch to be disabled or enabled
 * @property {boolean} unavailable - Sets the swatch is be unavailable or available
 * @property {function} onSwatchClick - Function to be called when swatch is clicked
 * @property {string} colorCode - color code for Swatch used in color filter
 * @property { bool } isHover - defaultProps.isHover -  false
 * @property { bool } withHoverAndSelected - defaultProps.withHoverAndSelected - false
 */
export const propTypes = {
  /** Sets the image src */
  src: PropTypes.string,
  /** Sets the alt text */
  alt: PropTypes.string,
  /** Sets the swatch as selected if true and vice versa */
  selected: PropTypes.bool,
  /** Sets width of the swatch */
  width: PropTypes.number,
  /** Sets height of the swatch */
  height: PropTypes.number,
  /** Sets the swatch to be disabled or enabled */
  disabled: PropTypes.bool,
  /** Sets the swatch is be unavailable or available */
  unavailable: PropTypes.bool,
  /** Function to be called when swatch is clicked */
  onSwatchClick: PropTypes.func,
  /** Set color code for Swatch div */
  colorCode: PropTypes.string,
  /** Set isHover for Swatch image css changes */
  isHover: PropTypes.bool,
  /** Set withHoverAndSelected for Swatch hover and selected css */
  withHoverAndSelected: PropTypes.bool
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {boolean} selected=false - The default value of the selection of swatch is false.
 * @property {number} width=40 - The default value of width is 40.
 * @property {number} height=40 - The default value of height is 40.
 * @property {boolean} disabled=false - The default value of disabled is false.
 */
export const defaultProps = {
  selected: false,
  width: 40,
  height: 40,
  disabled: false
};

Swatch.propTypes = propTypes;
Swatch.defaultProps = defaultProps;

export default Swatch;
