/**
 * This is a ProductRailVertical Component. This Component is used to render the Product Rail Vertical. This component will display a list of MiniProductCard.
 *
 * @module views/Organisms/ProductRailVertical
 * @memberof -Common
 */
import './ProductRailVertical.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { hasItems } from '@ulta/core/utils/array/array';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';

import AddToBagButton from '../AddToBagButton/AddToBagButton';
import MiniProductCard from '../MiniProductCard/MiniProductCard';

/**
 * Represents a ProductRailVertical component
 *
 * @method
 * @param {ProductRailVerticalProps} props - React properties passed from composition
 * @returns ProductRailVertical
 */
export const ProductRailVertical = function( props ){
  const {
    title,
    countLabel,
    items,
    componentKey,
    invokeMutation
  } = props;

  if( !hasItems( items ) ){
    return null;
  }


  return (
    <div className='ProductRailVertical'>
      <div className='ProductRailVertical__header'>
        <Text textStyle='title-6'
          htmlTag='h2'
        >
          { title }
        </Text>
      </div>
      { !!countLabel && (
        <Text textStyle='body-2'
          htmlTag='p'
          color='neutral-600'
        >
          { countLabel }
        </Text>
      ) }
      <ul className='ProductRailVertical__items'>
        { items.map( ( item, index ) => (
          <li key={ `${componentKey}-product-rail-vertical-${index}` }>
            <div
              role='group'
              aria-label='product'
            >
              { /* TODO: this wrapping link needs to be replaced when palette 2.0 comes out */ }
              <Link_Huge action={ item.action }>
                <MiniProductCard
                  image={ item.image }
                  brandName={ item.brandName }
                  productName={ item.productName }
                  price={ item.listPrice }
                  salePrice={ item.salePrice }
                  reviewCount={ item.reviewCount }
                  rating={ item.rating }
                  reviewAccessibilityLabel={ item.reviewAccessibilityLabel }
                  promoText={ item.promoText }
                  variantTypeLabel={ item.variantLabel }
                  dataCapture={ item.dataCapture }
                />
              </Link_Huge>
              { item.addToBagAction && <div className='ProductRailVertical__addToBag'>
                <AddToBagButton
                  addToBagAction={ item.addToBagAction }
                  invokeMutation={ invokeMutation }
                  productId={ item.productId }
                  skuId={ item.skuId }
                  isSecondary
                ></AddToBagButton>
              </div> }
              { item.viewOptionAction && (
                <div className='ProductRailVertical__addToBag'>
                  <Button
                    compact={ true }
                    secondary={ true }
                    onClick={ ( e ) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleRedirect( { url: item.viewOptionAction.url } );
                    } }
                    action={ item.viewOptionAction }
                    label={ item.viewOptionAction.label }
                  ></Button>
                </div>
              ) }
            </div>
          </li>
        ) ) }
      </ul>
    </div>
  );
};


/**
 * property type definitions
 * @typedef ProductRailVerticalProps
 * @type object
 * @property {string} title - This is recent searches clear all label
 * @property {string} countLabel - This is recent searches label for current recent searches
 * @property { array } items - This is array of recent search strings
 * @property {string} componentKey - provided by LayerHost, timestamp of the last time the component was updated
 */
export const propTypes = {
  title: PropTypes.string,
  countLabel: PropTypes.string,
  items: PropTypes.array,
  componentKey: PropTypes.number
};

ProductRailVertical.propTypes = propTypes;

export default ProductRailVertical;
