/**
 * Namespace for the web-common-ssr repository.
 * @namespace -Common
 */
import 'core-js/stable';

// import 'core-js/es/global-this';
// import 'regenerator-runtime/runtime';
/**
 * Namespace for the web-common-ssr repository.
 * @namespace -Common
 */
import Routes from '@ulta/core/components/Routes/Routes';
import { renderContainer } from '@ulta/core/utils/dom/dom';
import { isStagingEnvironment } from '@ulta/core/utils/domain/domain';
import { getRenderer } from '@ulta/core/utils/renderer/renderer';

import constants from '@ulta/utils/constants/constants';

export function render(){
  const externalHeader = document.getElementById( constants.HFN.EXTERNAL_HEADER );
  // TODO: get data attributes from header elementID
  const externalFooter = document.getElementById( constants.HFN.EXTERNAL_FOOTER );
  // TODO: get data attributes from footer elementID
  const dsRoot = document.getElementById( constants.APPLICATION_ROOT );

  const options = {
    ModuleRoutes: Routes,
    renderer: getRenderer( false ),
    /* window.__GRAPHQL_DOMAIN__, window.__GRAPHQL_URI__, and window.__TEALIUM_ENVIRONMENT__ are from SSR and should not be removed under any condition */
    googleMapsApiKey: window.__GOOGLE_MAPS_API_KEY__ || '',
    graphqlDomain: window.__GRAPHQL_DOMAIN__ || 'https://local.ulta.com:5100',
    graphqlURI: window.__GRAPHQL_URI__ || 'v1/client/dxl/graphql?ultasite=en-us&User-Agent=gomez',
    graphqlGetUri: window.__GRAPHQL_GET_URI__ || 'dxl/graphql?ultasite=en-us&User-Agent=gomez',
    graphqlServerUri: window.__GRAPHQL_SERVER_URI__ || 'v1/client/dxl/graphql?ultasite=en-us&user-agent=gomez',
    isBot: window.__IS_BOT__,
    isDXLSessionManagement: window.__IS_DXL_SESSION_MANAGEMENT__,
    isNative: window.__IS_NATIVE__ || false,
    isStaging: window.__IS_STAGING__ || false,
    paypalEnvironment: window.__PAYPAL_ENVIRONMENT__ || 'sandbox',
    powerReviewsSdk: window.__POWER_REVIEWS_SDK__ || '',
    previewDate: window.__ULTA_PREVIEW_DATE__ || undefined,
    stagingHost: window.__ULTA_STAGING_HOST__ || undefined,
    stagingWaitingRoomRetryCount: window.__STAGING_WAITING_ROOM_RETRY_COUNT__,
    stagingWaitingRoomRetryInterval: window.__STAGING_WAITING_ROOM_RETRY_INTERVAL__,
    tealiumEnvironment: window.__TEALIUM_ENVIRONMENT__ || 'qa',
    typeaheadMinInputLength: window.__TYPEAHEAD_MIN_INPUT_LENGTH__ || 3,
    rewardsPointsLimit: window.__REWARDS_POINTS_LIMIT__ || 100,
    recentlyViewedLimit: window.__RECENTLY_VIEWED_LIMIT__ || 20,
    enablePersistentSearch: window.__ENABLE_PERSISTENT_SEARCH__ || false,
    enableGetForQueries: window.__ENABLE_GET_FOR_QUERIES__ || false,
    serverRouteUtility: window.__SERVER_ROUTE_UTILITY__ || '',
    applePaySessionValidationEndPoint: window.__SERVER_ENDPOINT_APPLEPAY_SESSION_VALIDATION__ || '',
    salsifyClientId : window.__SALSIFY_CLIENT_ID__ || '',
    findationApiKey: window.__FINDATION_API_KEY__ || '',
    findationTestingMode: window.__FINDATION_TESTING_MODE__ || false
  };

  // Patch for dev mode server
  if( process.env.NODE_ENV === 'development' ){
    const url = new URL( global.location.href );
    options.previewDate = url.searchParams.get( 'previewDate' );
    options.stagingHost = url.searchParams.get( 'stagingHost' );
    options.isStaging = isStagingEnvironment( global.location.href );
  }

  externalHeader &&
    renderContainer( {
      ...options,
      targetElementId: constants.HFN.EXTERNAL_HEADER,
      locationPath: constants.HFN.header,
      isATG: true,
      isThirdParty: true
      // TODO: pass Header/Footer values from data attributes
    } );

  externalFooter &&
    renderContainer( {
      ...options,
      targetElementId: constants.HFN.EXTERNAL_FOOTER,
      locationPath: constants.HFN.footer,
      isATG: true,
      isThirdParty: true
      // TODO: pass Header/Footer values from data attributes
    } );

  dsRoot &&
    renderContainer( {
      ...options,
      isATG: false,
      targetElementId: constants.APPLICATION_ROOT
    } );
}

render();
