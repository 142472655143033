
import 'react-datepicker/dist/react-datepicker.css';
import './PreviewProvider.scss';

import React, { createContext, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import SlidingPane from 'react-sliding-pane';

import { Form, Formik, useFormikContext } from 'formik';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import InputField from '../../components/InputField_New/InputField_New';
import { Apollo_Client_GET } from '../../utils/apollo_client/apollo_client';


export let staginghost;
export let timestamp;

/**
 * Represents a PreviewProvider component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns PreviewProvider
 */
export const PreviewProvider = function( props ){
  const [isPanelOpen, setPanelState] = useState( props.isPanelOpen );
  const [startDate, setStartDate] = useState( new Date( parseFloat( props.timestamp, 10 ) ) );

  staginghost = props.stagingHost;
  timestamp = props.timestamp;

  return (
    <PreviewContext.Provider>
      { !!( staginghost && timestamp ) &&
        <>
          <div className='PreviewProvider__Button'>
            <Button
              icon
              iconImage='Edit'
              iconSize='s'
              hiddenLabel='Edit'
              onMouseDown={ () => setPanelState( true ) }
            />
          </div>
          <SlidingPane
            className='PreviewProvider'
            shouldCloseOnEsc
            closeIcon={
              <Icon
                size='s'
                name='X'
              />
            }
            isOpen={ isPanelOpen }
            from='left'
            width='40%'
            title='Preview Options'
            subtitle='Use this panel to configure the preview environment'
            onRequestClose={ () => {
              setPanelState( false );
            } }
          >
            <div className='PreviewProvider__ActiveData'>
              <Formik
                initialValues={ {
                  StartDate: startDate
                } }
              >

                <Form>
                  <DatePicker
                    showTimeSelect
                    selected={ startDate }
                    onChange={ date => setStartDate( date ) }
                    dateFormat='MM/dd/yyyy HH:mm:ss'
                    customInput={ <DateInputField /> }
                  />
                </Form>
              </Formik>
            </div>
          </SlidingPane>
        </>
      }
      { props.children }
    </PreviewContext.Provider>
  );
};

export const DateInputField = ( { value, onClick } ) => {
  const { setFieldValue } = useFormikContext();

  useEffect( () => {
    setFieldValue( 'StartDate', value );
    timestamp = new Date( value ).getTime();
    // set the provider values to be pulled in ApolloClient
    let mainDomain = staginghost.split( '.' );
    // set the new timestamp in teh arrawy
    let splitDomain = mainDomain[ 0 ].split( '-' );
    // replace existing timestamp with the new timestamp
    splitDomain[ 2 ] = timestamp;
    mainDomain[ 0 ] = splitDomain.join( '-' );
    staginghost = mainDomain.join( '.' );
    Apollo_Client_GET.reFetchObservableQueries();
  }, [value] );

  return (
    <InputField
      required
      name='StartDate'
      label='START DATE'
      placeholder='mm/dd/yy HH:mm:ss'
      onClick={ onClick }
      autoComplete='StartDate'
    />
  );
};

export const propTypes =  {
};
export const defaultProps =  {
  isPanelOpen: false
};

PreviewProvider.propTypes = propTypes;
PreviewProvider.defaultProps = defaultProps;

export default PreviewProvider;

export const initialState = {
  staginghost: undefined,
  timestamp: undefined
};



export const PreviewContext = createContext( initialState );

export const usePreviewContext = ( ) => useContext( PreviewContext );
